.wrapper {
  /* Should be bleed on small screens */
  @apply px-0 sm:px-8 mt-24 lg:mt-0;

  & :global(.on-plate) {
    @apply bg-good-green-10;
  }
}

/* FIXME does this class every occur??? */
.single {
  @apply bg-summer-green-50 pb-13 mt-22;
  @apply lg:mt-0 lg:pb-0 lg:bg-transparent;
}

.innerWrapper {
  @apply lg:py-13 lg:px-10 lg:pl-0 xl:px-15 xl:pl-0 lg:ml-13;

  &:global(.no-padding) {
    @apply py-0;
  }
}

.contentWrapper {
  @apply flex flex-col items-start justify-center px-4 sm:px-8 pt-8 pb-12 lg:p-0;

  &:global(.no-padding) {
    @apply pb-0;
  }
}

.meta {
  @apply text-sm mt-3.5 lg:mt-5;
}

.imageHolder {
  @apply px-4 sm:px-8 relative -top-12 -mb-12;
  @apply lg:top-0 lg:mb-0 lg:px-0 lg:-ml-13;
}

.figure {
  @apply relative h-full aspect-w-5 aspect-h-5 xl:aspect-w-6 image-has-hover-effect;
}

.contentGrid {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-x-6 xl:gap-x-16;

  &:global(.noImage) {
    @apply grid-cols-1;
  }
}

.image {
  @apply object-cover object-center w-full h-full;
}

/* Right layout - Image to the right */
.layout-right {
  & .innerWrapper {
    @apply lg:pl-10 lg:pr-0 lg:ml-0 lg:mr-13;
  }

  & .contentGrid .imageHolder {
    @apply lg:ml-0 lg:-mr-13 lg:col-start-2 lg:row-start-1;
  }
}

.layout-third {
  & .innerWrapper {
    @apply ml-0;
  }
}
