.mainArticle {
  @apply pb-2.5 !important;
}

.wrapper {
  @apply pt-10;
  @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-7.5 gap-x-0 md:gap-x-7.5 lg:gap-x-10;

  & :global(h2) {
    @apply font-sans text-xl mb-2;
  }
}

.buttonWrapper {
  @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
}

.button {
  @apply text-center mx-auto block w-full md:w-6/12 mt-13 max-w-1xl;
}

.articleButton {
  @apply mt-8;
}

.plate {
  background: linear-gradient(#101010 15%, transparent 15% 100%);
}

@media screen(lg) {
  .plate {
    background: linear-gradient(#101010 40%, transparent 40% 100%);
  }
}
