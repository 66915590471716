/* theme/content-block */
.wrapper {
  @apply bg-white text-black-100 rounded;

  & :global(.inner-wrapper) {
    @apply p-8 flex flex-col flex-nowrap h-full;
  }

  & :global(div[data-block="core/button"]) {
    @apply mt-auto;
  }

  & :global(div[data-block="core/paragraph"]) {
    @apply ml-0 sm:max-w-[90%];
  }
}

.theme-good-green {
  @apply bg-good-green-10;
}

.theme-good-blue {
  @apply text-white bg-good-blue-100;
}
